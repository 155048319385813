import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e84127",
    },
    text: {
      primary: "#040406",
      light: "#adaeb3",
      lighter: "#787676",
    },
    hashtag: {
      main: "#ff7373",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 4px 40px 0px #0000001a",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#353535",
          borderWidth: "1px",
        },
      },
    },
  },
  shadows: {
    custom: "1px 1px 5px 0px rgba(0, 0, 0, 0.2509803922)",
  },
});

export default theme;
