import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { LoginUser } from "../../../reducers/login";
import { PostCallApi } from "../../../Action/Action";
import eye from "../../../assets/icons/eye.svg";
import eyeOff from "../../../assets/icons/eye-off.svg";
import ButtonLoading from "../../../assets/icons/buttonLoading.svg";
import LoginImage from "../../../assets/images/loginImage.png";
import { setOpen as setOpenDialog } from "../../../reducers/globalpopup";
import "./index.scss";

const LoginModal = ({ open, onClose }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    let success = "Login Successfully";
    let invalid = "Invalid credentials";
    let sendData = { ...data };
    let seData = {
      url: `/v1/user/login`,
      body: sendData,
      // headers: headers,
    };

    let res = await PostCallApi(seData);
    setIsLoading(false);
    if (res?.status === 200) {
      // debugger
      let newUserD = {
        ...res.data,
        usPs: sendData?.password,
        authenticate: true,
      };
      setTimeout(() => {
        toast.success(success, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }, 500);
      // axios.defaults.headers.common["Authorization"] = `bearer ${newUserD?.data?.token}`;
      dispatch(LoginUser(newUserD));
      navigate("/", { replace: true });
      dispatch(setOpenDialog(false));
    } else {
      toast.error(invalid, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      className="login_dialog"
    >
      <DialogTitle className="dialog-title">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-content" sx={{ padding: "0px" }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box style={{ padding: "0px 24px", width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <img src={LoginImage} alt="" className="img" />
              <div>
                <p className="form-heading">
                  Welcome <span>Back!</span>
                </p>
                <p className="form-head-description">
                  Access your personalized feed and notifications.
                </p>
              </div>
            </Box>

            <form
              className="d-flex flex-column"
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: "100%" }}
            >
              <div className="form-group">
                <label className="label">{t("EmailAddress")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Email Address"
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please Enter Valid Email",
                    },
                  })}
                />
                {errors.email && (
                  <span role="alert" className="error_text">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>{t("Password")}</label>
                <div className="password">
                  <input
                    name="current-password"
                    className="form-control"
                    placeholder="******"
                    type={passwordShown ? "text" : "password"}
                    {...register("password", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      pattern: {
                        value: /^\S+$/,
                        message: "Whitespace is not allowed in this field",
                      },
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Max 15 characters",
                      },
                    })}
                  />
                  <span className="eye" onClick={togglePasswordVisiblity}>
                    <img
                      src={passwordShown === true ? eye : eyeOff}
                      alt="eye"
                    />
                  </span>
                </div>
                {errors.password && (
                  <span role="alert" className="error_text">
                    {errors.password.message}
                  </span>
                )}
                <div
                  className="links"
                  style={{ textAlign: "right", marginTop: "8px" }}
                >
                  <Link
                    to="/forgot-passowrd"
                    target="_blank"
                    className="forgot-password"
                  >
                    {t("ForgotPassword")}
                  </Link>
                </div>
              </div>
              <Button
                variant="outlined"
                type="submit"
                name="submit"
                className="theme_btn"
                disabled={isLoading}
              >
                {isLoading ? (
                  <img src={ButtonLoading} alt="loading" />
                ) : (
                  t("login")
                )}
              </Button>
            </form>
          </Box>

          <div
            style={{
              background: "#DADADA",
              height: "1px",
              margin: "20px 0px",
              width: "100%",
            }}
          ></div>

          <Typography
            variant="body2"
            align="center"
            className="dont-have-account"
          >
            Don’t have an account?{" "}
            <Link to="/register" target="_blank" className="text-signup">
              {t("Signup")}
            </Link>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
