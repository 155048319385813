import React from "react";
import { useSelector } from "react-redux";
import { authRoutesList } from "../../../Routes";
import { Navigate, useLocation } from "react-router-dom";

const AuthHoc = (WrappedComponent) => {
  const WithAuth = (props) => {
    const isLoggedIn = useSelector(
      (state) => state?.login?.LoginDetails?.authenticate
    );
    const accountInformation = useSelector((state) => state.login.LoginDetails);
    const { pathname } = useLocation();
    const isAuthRoute = authRoutesList.find((route) => route.path === pathname);

    return isAuthRoute && !isLoggedIn ? (
      <Navigate to={"/"} />
    ) : (
      <WrappedComponent
        {...props}
        isLoggedIn={isLoggedIn}
        accountInfo={accountInformation}
      />
    );
  };

  return WithAuth;
};

export default AuthHoc;
