import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

export const globalPopupSlice = createSlice({
  name: "globalPopup",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = globalPopupSlice.actions;

export default globalPopupSlice.reducer;
