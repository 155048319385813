import React, { Suspense } from "react";
import "./App.scss";
import "animate.css";
import AppRoute from "./Routes";
import { CircularProgress, ThemeProvider } from "@mui/material";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<CircularProgress />}>
        {" "}
        <AppRoute />{" "}
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
