import React from "react";
import { Outlet } from "react-router-dom";
import AuthHoc from "../AuthHoc";

import { useBlocker } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../../reducers/globalpopup";
import LoginModal from "../LoginModal";
import { authRoutesList } from "../../../Routes";

const RouteGuard = ({ isLoggedIn }) => {
  const { open } = useSelector((state) => state.globalPopup);
  const { open: isOpen, reset } = useCustomBlocker(isLoggedIn);
  const dispatch = useDispatch();
  const handleClose = () => {
    reset();
    dispatch(setOpen(false));
  };

  return (
    <>
      <LoginModal open={isOpen || open} onClose={handleClose} />
      <Outlet />
    </>
  );
};

export default AuthHoc(RouteGuard);

export function useCustomBlocker(isBlocked) {
  let shouldBlock = React.useCallback(
    ({ currentLocation, nextLocation }) => {
      const isAuthRouteList = authRoutesList.find(
        (i) => i.path === nextLocation.pathname
      );
      return (
        isAuthRouteList &&
        !isBlocked &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },

    [isBlocked]
  );
  let blocker = useBlocker(shouldBlock);
  const open = blocker.state === "blocked";

  return {
    open,
    reset: () => {
      blocker?.reset?.();
    },
  };
}
